"use client";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const BrainVisualizerIntro = ({ dialogOpen, setDialogOpen, userOpened }) => {
  const [checked, setChecked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagePaths = [
    "/img/BV-intro/1.jpg",
    "/img/BV-intro/2.jpg",
    "/img/BV-intro/3.jpg",
    "/img/BV-intro/4.jpg",
    "/img/BV-intro/5.jpg",
  ];

  // Close
  function handleClose() {
    setDialogOpen(false);
  }

  // "Don't show" checkbox
  function handleChange(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      localStorage.setItem("dontShowBrainVisualizerIntro", "true");
    } else {
      localStorage.removeItem("dontShowBrainVisualizerIntro");
    }
    setChecked(isChecked);
  }

  // Prev slide
  function goPrev() {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }

  // Next slide
  function goNext() {
    if (currentIndex < imagePaths.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }

  return (
    <>
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          {/* Title & close button */}
          <DialogTitle id="alert-dialog-title">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                Welcome to the Brain Visualizer!
              </Typography>
              <IconButton onClick={() => setDialogOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>

          {/* Slide images */}
          <DialogContent>
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imagePaths[currentIndex]}
                  alt="Brain visualizer intro"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    maxHeight: "70vh",
                    border: "2px solid #616161",
                    borderRadius: "15px",
                  }}
                />
              </Box>
            </Box>
          </DialogContent>

          {/* Actions */}
          <DialogActions>
            {/* Don't show? checkbox */}
            {!userOpened && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    size="small"
                    sx={{
                      color: "#c9c9c9",
                    }}
                  />
                }
                label="Don't show again"
                sx={{ width: "350px", pl: "8px", m: 0, color: "#c9c9c9" }}
              />
            )}

            {/* Previous/next buttons */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "flex-end", md: "center" },
                gap: 2,
                pr: { xs: "24px", md: 0 },
              }}
            >
              <Button
                onClick={goPrev}
                disabled={currentIndex === 0}
                variant="outlined"
              >
                Previous
              </Button>
              <Button
                onClick={goNext}
                disabled={currentIndex >= imagePaths.length - 1}
                variant="outlined"
              >
                Next
              </Button>
            </Box>

            {/* Centering empty box */}
            {!userOpened && (
              <Box
                sx={{ display: { xs: "none", md: "flex" }, width: "358px" }}
              />
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BrainVisualizerIntro;
