import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// Start play session
export async function startPlay(accessToken, embodimentId) {
  try {
    // Validate arg
    apiValidateArguments({ embodimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/plays/play`,
      "POST",
      {
        embodiment_id: embodimentId,
      },
      { Authorization: accessToken }
    );

    logger("Response in startPlay function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in startPlay function:", error);
    throw error;
  }
}

// Stop play session
export async function stopPlay(accessToken) {
  try {
    // Validate arg
    if (!accessToken) {
      throw new Error("Missing access token.");
    }

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/plays/stop`,
      "POST",
      null,
      { Authorization: accessToken }
    );

    logger("Response in stopPlay function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in stopPlay function:", error);
    throw error;
  }
}
