"use client";
import { useEffect, useState } from "react";
import {
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  AutoAwesome as AutoAwesomeIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { getEmbodimentRegistrationLink } from "../api/embodimentManagement";

const MagicLink = ({
  accessToken,
  embodimentId,
  setError,
  link,
  setLink,
  parentOpen,
  isAuxiliary,
}) => {
  const [copied, setCopied] = useState(false);

  // If in Auxiliary section, fetch on render & allow copy link direct from button click
  useEffect(() => {
    if (isAuxiliary && !link) {
      fetchRegistrationLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuxiliary]);

  useEffect(() => {
    if (parentOpen === false) setLink("");
  }, [parentOpen, setLink]);

  async function fetchRegistrationLink() {
    try {
      const res = await getEmbodimentRegistrationLink(
        accessToken,
        embodimentId
      );
      const link = res.data;
      setLink(link);
    } catch (err) {
      console.error(err);
      setError(
        "Sorry, there was an error fetching the magic link. Please reload if the issue persists."
      );
    }
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleClickAway = () => {
    setCopied(false);
  };

  return (
    <>
      {link && !isAuxiliary ? (
        // If link exists & not Auxiliary section, display it in a TextField with a copy button
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            variant="outlined"
            value={link}
            label="Magic Link"
            readOnly
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
                    <IconButton onClick={handleCopyClick}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{
              width: "fit-content",
              maxWidth: "100%",
              position: "relative",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                },
                "&:hover fieldset": {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                },
                "&.Mui-focused fieldset": {
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                },
              },
              "& .MuiInputAdornment-root": {
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: "-30px",
                  width: "30px",
                  height: "30px",
                  background:
                    "linear-gradient(to right, rgba(14, 19, 32, 0), rgba(14, 19, 32, 1))",
                  zIndex: 1,
                },
              },
            }}
          />
        </ClickAwayListener>
      ) : (
        // Else, show button to retrieve link
        <Tooltip
          title={
            copied && isAuxiliary
              ? "Copied!"
              : isAuxiliary
              ? "Copy to clipboard"
              : undefined
          }
        >
          <span>
            <Button
              variant={isAuxiliary ? "outlined" : "contained"}
              size="small"
              onClick={isAuxiliary ? handleCopyClick : fetchRegistrationLink}
              disabled={isAuxiliary && !link}
            >
              <AutoAwesomeIcon
                sx={{ mr: "3px", color: "gold.main", fontSize: "1rem" }}
              />{" "}
              Magic Link
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default MagicLink;
