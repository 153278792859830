import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";

// Get tutorial slides etc.
export async function getDemosData(token = process.env.NEXT_PUBLIC_PROJECT_ID) {
  try {
    // Validate args
    if (!token) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/demos/outline`,
      "GET",
      null,
      { Authorization: token }
    );

    logger("Response in getDemosData function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getDemosData function:", error);
    throw error;
  }
}
