import { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAuth } from "./auth";
import config from "../config";
import { logger } from "./logger";
import { updateUserHeartbeat, checkUserHeartbeat } from "../api/userManagement";
import { useRouter } from "next/navigation";

// Track user activity & logout user if inactive for a certain amount of time
// inform backend 1x per idleTimeoutHeartbeatFrequency if user active in past minute
// if user inactive alerttime minutes, show alert and stop tracking activity until they click ok
// if user still inactive logoutTime minutes, log out

const InactivityTimer = () => {
  const auth = useAuth();
  const router = useRouter();
  // Times in milliseconds
  const alertTime = config.idleTimeoutAlert;
  const logoutTime = config.idleTimeout;
  const informBackendTime = config.idleTimeoutHeartbeatFrequency;
  // Last active time as date string
  const lastActiveRef = useRef(new Date());
  // Whether tab is active
  const isTabActiveRef = useRef(true);
  // Allow removing activity listeners from wherever
  const [shouldListen, setShouldListen] = useState(true);
  // Alert modal
  const [warnUser, setWarnUser] = useState(false);

  // Reset timer
  const setToCurrentTime = () => {
    lastActiveRef.current = new Date();
  };

  // Respond to user confirmation on warning alert (remove alert, set to current time, resume listening)
  function handleConfirmActive() {
    logger("⏰ User confirmed activity. Resetting time...");
    setWarnUser(false);
    setToCurrentTime();
    setShouldListen(true);
  }

  // Logout user (stop listening, sign out, redirect to home page)
  function handleLogout() {
    setShouldListen(false);
    auth.signout();
    router.push("/");
  }

  // Listen for user activity & set last active time
  useEffect(() => {
    // Reset timer when tab becomes active
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        logger("⏰ Tab is active");
        setToCurrentTime();
        isTabActiveRef.current = true;
      } else {
        logger("⏰ Tab is inactive");
        isTabActiveRef.current = false;
      }
    };
    // Add listeners on mount/when dependencies triggered
    if (shouldListen) {
      logger("⏰ Adding listener for user activity...");
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }
    // Cleanup/listener removal function
    const cleanup = () => {
      logger("⏰ Removing listener for user activity...");
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // Remove listeners if dependency says so
    if (!shouldListen) {
      cleanup();
    }
    // Return cleanup function for component unmount
    return cleanup;
  }, [shouldListen]);

  // Check user last active time on backend
  const checkBackendTime = async () => {
    try {
      logger("⏰ Checking backend last active time...");
      const token = auth.user.accessToken;
      // get last active time from server
      const res = await checkUserHeartbeat(token);
      let lastActiveServerTime = res.data; // e.g. "2024-05-10 21:56:45.954000"
      // validate data format
      const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d+$/;
      if (!lastActiveServerTime || !dateRegex.test(lastActiveServerTime)) {
        throw new Error("Received date is in an invalid format.");
      }
      // convert server time to a format JS understands
      lastActiveServerTime = lastActiveServerTime.replace(" ", "T") + "Z";
      const serverDateTime = new Date(lastActiveServerTime);
      // set last active time to backend time if more recent
      if (serverDateTime > lastActiveRef.current) {
        lastActiveRef.current = serverDateTime;
      }
      // return serverDateTime;
    } catch (error) {
      console.error("Failed to check user activity:", error);
      lastActiveRef.current = new Date();
    }
  };

  // Compare last active time with current time, backend, alert time, and logout time
  async function compareTimes() {
    const currentTime = new Date();
    if (isTabActiveRef.current === true) {
      console.log("Tab is active");
      setToCurrentTime();
    }
    let timeDifference = currentTime - lastActiveRef.current;

    if (timeDifference <= informBackendTime && auth.user) {
      logger(
        `⏰ Time since last active is ${
          timeDifference / 1000
        } seconds. Informing backend of user activity...`
      );
      // Inform backend of user activity if active lately
      const informBackend = async () => {
        const token = auth.user.accessToken;
        await updateUserHeartbeat(token);
      };
      informBackend();
    } else if (timeDifference > logoutTime) {
      // Set last active time to backend time if more recent
      await checkBackendTime();
      timeDifference = currentTime - lastActiveRef.current;
      // Log user out if inactive for logoutTime minutes
      if (timeDifference > logoutTime) {
        logger(
          `⏰ Time since last active is ${
            timeDifference / 1000
          } seconds. warnUser is ${warnUser}. Logging out user...`
        );

        handleLogout();
        // If the last active time is less than the alert time, close the alert
      } else if (timeDifference < alertTime) {
        handleConfirmActive();
      }
    } else if (!warnUser && timeDifference > alertTime) {
      // Set last active time to backend time if more recent
      await checkBackendTime();
      timeDifference = currentTime - lastActiveRef.current;
      // Warn user if inactive for alertTime minutes
      if (timeDifference > alertTime) {
        logger(
          `⏰ Time since last active is ${
            timeDifference / 1000
          } seconds. warnUser is ${warnUser}. Setting alert...`
        );
        setShouldListen(false);
        setWarnUser(true);
      }
    }
  }

  // Inform backend of user activity every informBackendTime, if they have been active within that amount of time
  useEffect(() => {
    const interval = setInterval(() => {
      compareTimes();
    }, informBackendTime);

    return () => clearInterval(interval);
    // It wants compareTimes in this array...it ain't gonna change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, informBackendTime]);

  return (
    <Dialog
      open={warnUser}
      onClose={() => setWarnUser(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      ModalProps={{ style: { zIndex: 1400 } }}
      disableBackdropClick
    >
      <DialogTitle id="alert-dialog-title">Inactivity Warning</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you do not respond to this alert, you will be logged out in{" "}
          {Math.max(
            0,
            Math.floor(
              (logoutTime - (new Date() - lastActiveRef.current)) / 60000
            )
          ) || "a few"}{" "}
          minute(s) due to inactivity.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmActive} variant="contained">
          I&apos;m here!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivityTimer;
