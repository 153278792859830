import { useEffect, useState } from "react";
import CustomDialog from "../CustomDialog";
import { getFitnessStats } from "../../api/feagiTraining";
import FormatObject from "../../util/FormatObject";
import { logger } from "../../util/logger";

const NavbarFitness = ({
  fitnessOpen,
  setFitnessOpen,
  sessionId,
  clusterId,
}) => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    async function fetchStats() {
      const res = await getFitnessStats(sessionId, clusterId);
      logger("fitness data:", res.data);
      setStats(res.data);
    }

    if (sessionId && clusterId) {
      fetchStats();
    }
  }, [sessionId, clusterId]);

  // Check if object is empty
  function hasContent(obj) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return true;
      }
    }
    return false;
  }

  return (
    <CustomDialog
      header="Embodiment Fitness"
      isOpen={fitnessOpen}
      handleClose={() => setFitnessOpen(false)}
      richText={
        hasContent(stats) ? (
          <FormatObject data={stats} />
        ) : (
          <>No stats available at this time.</>
        )
      }
    />
  );
};

export default NavbarFitness;
