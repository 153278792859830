import { useEffect, useRef } from "react";
import { logger } from "../../util/logger";
import getStatusCodeString from "../../util/websocketStatusCodes";
import { useSearchParams } from "next/navigation";

function Bluetooth({
  isMicrobitOpen,
  setIsMicrobitOpen,
  isMicrobitConnected,
  setIsMicrobitConnected,
  embodiment,
  sessionId,
  clusterId,
  setSnackbar,
}) {
  const searchParams = useSearchParams();
  // Session & cluster IDs
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const sessionId = params.get("id") || params.get("session_id");
  // const clusterId = params.get("cluster") || params.get("cluster_id");
  // Embodiment
  const embodimentBluetoothProperties = embodiment?.bluetooth_properties;
  const embodimentId = embodiment?.embodiment_id;
  const embodimentCapabilities = embodiment?.capabilities;
  // WebSocket
  const wsRef = useRef(null);
  const wsUrl = `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9052?device=${embodimentId}`;
  // Bluetooth
  const bluetoothUARTRef = useRef(null);
  const MBIT_UART_SERVICE = embodimentBluetoothProperties?.service;
  const MBIT_UART_RX_CHARACTERISTIC = embodimentBluetoothProperties?.rx;
  const MBIT_UART_TX_CHARACTERISTIC = embodimentBluetoothProperties?.tx;
  const bluetoothSearchOptions = {
    acceptAllDevices: true,
    optionalServices: [MBIT_UART_SERVICE],
  };

  useEffect(() => {
    logger("session or cluster changed:", sessionId, clusterId);
    if (sessionId && clusterId && !wsRef.current) {
      console.log("Retrying websocket");
      connectWebSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, clusterId]);

  useEffect(() => {
    // Access query parameters
    const id = searchParams.get("id") || searchParams.get("session_id");

    // Do something when parameters change
    if (id) {
      console.log("searchParams changed:", id);
    }
  }, [searchParams]);

  // Format data for WS with controller
  const formatData = (data) => {
    if (!data) {
      console.error("Data from embodiment is null. Not sending.");
      return null;
    } else {
      return JSON.stringify({
        [embodimentId]: { data, timestamp: Date.now() },
      });
    }
  };

  // Connect/disconnect on switch click
  useEffect(() => {
    if (isMicrobitOpen && !isMicrobitConnected) {
      connectMicrobit();
    } else if (!isMicrobitOpen) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitOpen]);

  useEffect(() => {
    if (!isMicrobitConnected) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitConnected]);

  if (
    !MBIT_UART_SERVICE ||
    !MBIT_UART_RX_CHARACTERISTIC ||
    !MBIT_UART_TX_CHARACTERISTIC
  ) {
    console.error("Invalid or missing bluetooth properties");
    setIsMicrobitOpen(false);
    return null;
  }

  class MicroBitUART {
    constructor(rxCharacteristic, txCharacteristic) {
      this.rxCharacteristic = rxCharacteristic;
      this.txCharacteristic = txCharacteristic;
      this.decoder = new TextDecoder();
      this.txCharacteristic
        .startNotifications()
        .then((characteristic) => {
          characteristic.addEventListener(
            "characteristicvaluechanged",
            this.onCharacteristicValueChanged.bind(this)
          );
        })
        .catch((error) => {
          console.error("Error starting notifications:", error);
          disconnectMicrobit();
        });
    }

    onCharacteristicValueChanged(event) {
      let valueAsString = this.decoder.decode(event.target.value);
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        logger("got data from bt:", valueAsString);
        const formattedData = formatData(valueAsString);
        formattedData && wsRef.current.send(formattedData);
      } else {
        console.error("WebSocket not open. Cannot send data.");
      }
    }

    async send(data) {
      const encoder = new TextEncoder();
      const encoded = encoder.encode(data);
      await this.rxCharacteristic.writeValue(encoded);
      logger("Sent to Bluetooth device:", data);
    }
  }

  const connectWebSocket = () => {
    if (!sessionId || !clusterId) {
      console.error("Session or cluster ID is missing. Can't connect WS.");
      return;
    }
    const newWs = new WebSocket(wsUrl);
    newWs.onopen = () => {
      logger("WebSocket opened");
      if (embodimentCapabilities) {
        newWs.send(JSON.stringify(embodimentCapabilities));
        console.log("Sent embodiment capabilities to controller.");
        // const formattedData = formatData(embodimentCapabilities);
        // newWs.send(formattedData);
      } else {
        console.error(
          "Bluetooth embodiment capabilities undefined/null. Cannot send."
        );
      }
      setInterval(() => {
        if (newWs.readyState === WebSocket.OPEN) {
          newWs.send(JSON.stringify({ type: "ping" }));
          console.log("Sent ping to controller.");
        } else {
          console.error("WebSocket not open. Cannot send ping.");
        }
      }, 5000);
    };
    newWs.onmessage = (event) => {
      logger("Received message from controller:", event.data);
      if (!bluetoothUARTRef.current) {
        console.error("Bluetooth UART not initialized. Ignoring message.");
      } else if (!event.data) {
        console.error("Data from controller is null. Ignoring message.");
      } else {
        bluetoothUARTRef.current?.send(event.data);
      }
    };
    newWs.onerror = (error) => console.error("WebSocket error:", error);
    newWs.onclose = (event) => {
      logger(
        `WebSocket connection closed: Code ${event.code} ${getStatusCodeString(
          event.code
        )}.`
      );
    };
    wsRef.current = newWs;
  };

  const connectMicrobit = async () => {
    try {
      const device = await navigator?.bluetooth?.requestDevice(
        bluetoothSearchOptions
      );

      if (!device) {
        throw new Error(
          "Unable to get device info. This browser may not support Bluetooth. Please try a different browser."
        );
      }

      device.ongattserverdisconnected = () => {
        console.log("Bluetooth device disconnected");
        disconnectMicrobit();
      };

      const server = await device.gatt.connect();

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(5000);

      const service = await server.getPrimaryService(MBIT_UART_SERVICE);

      const rxCharacteristic = await service.getCharacteristic(
        MBIT_UART_RX_CHARACTERISTIC
      );

      const txCharacteristic = await service.getCharacteristic(
        MBIT_UART_TX_CHARACTERISTIC
      );

      if (!rxCharacteristic || !txCharacteristic) {
        throw new Error("Unable to get characteristic info to make a UART.");
      }

      const uart = new MicroBitUART(rxCharacteristic, txCharacteristic);

      if (!uart) {
        throw new Error("Unable to create Bluetooth UART.");
      }

      bluetoothUARTRef.current = uart;

      setIsMicrobitConnected(true);

      console.log("Bluetooth device connected");

      // Setup WebSocket connection after Bluetooth device is connected
      connectWebSocket();
    } catch (error) {
      const message = `Connection error: ${error}`;

      console.error(message);

      if (!message?.includes("User cancelled") && setSnackbar) {
        setSnackbar({ message, severity: "error" });
      }

      disconnectMicrobit();
    }
  };

  const disconnectMicrobit = () => {
    console.log("Disconnection initiated");
    if (wsRef.current) {
      console.log("Disconnect: closing websocket");
      wsRef.current.close();
      wsRef.current = null;
    }

    if (navigator?.bluetooth?.getDevices) {
      navigator.bluetooth.getDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.gatt.connected) {
            console.log("Disconnect: Disconnecting Bluetooth device");
            device.gatt.disconnect();
          }
        });
      });
    }

    if (bluetoothUARTRef.current) {
      console.log("Disconnect: Setting UART ref to null");
      bluetoothUARTRef.current = null;
    }

    console.log("Disconnect: Setting microbit/embodiment to closed");
    setIsMicrobitConnected(false);
    setIsMicrobitOpen(false);
  };

  // const toggleOpen = () => {
  //   if (isMicrobitConnected) {
  //     disconnectMicrobit();
  //   } else {
  //     connectMicrobit();
  //   }
  // };

  return (
    <>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography>Cutebot</Typography> */}
      {/* <IconButton
            sx={{ fontSize: "1rem", padding: 0 }}
            onClick={handleEmbodimentOpen}
          >
            <InfoIcon style={{ fontSize: "1.5rem", padding: "4px" }} />
          </IconButton> */}
      {/* </Box>
        <Switch
          onClick={
            browserInfo?.browser === "Safari"
              ? () => setUnsupportedAlertOpen(true)
              : toggleOpen
          }
          checked={!!isMicrobitConnected}
        />
      </Box>
      <Snackbar
        open={unsupportedAlertOpen}
        autoHideDuration={6000}
        onClose={() => setUnsupportedAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setUnsupportedAlertOpen(false)}
          severity="warning"
        >
          Safari does not currently support Bluetooth connectivity. Please
          switch to Chrome.
        </Alert>
      </Snackbar> */}
    </>
  );
}

export default Bluetooth;

// const helloClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("f60#");
//   // };

//   // const backwardClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("b60#");
//   // };

//   // const leftClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("l60#");
//   // };

//   // const rightClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("r60#");
//   // };

//   // const sayHelloBack = (message) => {
//   //   ourMicrobitUART && ourMicrobitUART.send("hello", "response");
//   // };

//   // const printMessage = () => {
//   //   setMessage("Hello, world!");
//   // };

/* <Box display="flex" justifyContent={"center"}>
        <button type="button" onClick={connectClicked}>
          Connect!
        </button>
        <button type="button" onClick={helloClicked}>
          Go forward
        </button>
        <button type="button" onClick={backwardClicked}>
          Go backward
        </button>
        <button type="button" onClick={leftClicked}>
          Go left
        </button>
        <button type="button" onClick={rightClicked}>
          Go right
        </button>
        <button onClick={printMessage}>Print Message</button>
        <p>{message}</p>
      </Box>  */

/* <div>
        {log.map((logEntry, index) => (
          <div key={index}>{logEntry}</div>
        ))}
      </div> */
