"use client";

import { ReactNode } from "react";
import { usePathname } from "next/navigation";
import Box from "@mui/material/Box";

type ClientLayoutProps = {
  children: ReactNode;
};

const ClientLayout = ({ children }: ClientLayoutProps) => {
  const pathname = usePathname();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto", // Header, content, footer
        minHeight: "100vh",
        backgroundColor: pathname.startsWith("/auth")
          ? "black"
          : "background.default",
      }}
    >
      {children}
    </Box>
  );
};

export default ClientLayout;
