"use client";

import React from "react";
import { AuthProvider } from "../util/auth";
import { ThemeProvider } from "../styles/theme";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </AuthProvider>
  );
}
