import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import CustomDialog from "../../CustomDialog";
import { exportRegion, getRegionTitles } from "../../../api/feagiBrainRegions";
import { useSearchParams } from "next/navigation";

const GenomeExport = ({
  accessToken,
  genomeId,
  setError,
  setMessage,
  exportOpen,
  setExportOpen,
}) => {
  const searchParams = useSearchParams();
  const sessionId = searchParams.get("id");
  const clusterId = searchParams.get("cluster");
  const [regions, setRegions] = useState([]);

  // Fetch this FEAGI session's brain regions
  useEffect(() => {
    async function fetchRegions() {
      try {
        const res = await getRegionTitles(sessionId, clusterId);
        setRegions(res.data);
      } catch (err) {
        console.error(err);
        setError("Unable to fetch region titles");
      }
    }
    if (exportOpen && sessionId && clusterId) fetchRegions();
  }, [exportOpen, sessionId, clusterId, setError]);

  // Handle user selection of region
  async function handleSelect(regionId, regionTitle) {
    try {
      await exportRegion(
        accessToken,
        sessionId,
        genomeId,
        regionId,
        regionTitle
      );
      setMessage(
        "Region exported successfully. You should now see it in the genome list."
      );
      setExportOpen(false);
    } catch {
      console.error(
        "Error exporting genome. If you do not see your genome in the genome list, please try again."
      );
    }
  }

  return (
    <CustomDialog
      header="Export Brain Region"
      text="Select a region to export. This will add the selected region as a genome to your genome pool. (You can make it public later if you like.)"
      isOpen={exportOpen && regions.length > 0}
      handleClose={() => setExportOpen(false)}
      richText={
        <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
          {regions.map((region) => (
            <Card
              key={region[0]}
              sx={{
                position: "relative",
                overflow: "visible",
                width: "100%",
                "&:hover .hoverButton": {
                  opacity: 1,
                },
              }}
            >
              <CardContent
                sx={{
                  width: "100%",
                  pt: "16px",
                  pb: "16px !important",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Typography>{region[1]}</Typography>
                <Button
                  variant="contained"
                  onClick={() => handleSelect(region[0], region[1])}
                  className="hoverButton"
                  sx={{
                    opacity: { xs: 1, md: 0 },
                    transition: { md: "opacity 0.1s ease" },
                    fontWeight: "600",
                  }}
                >
                  Choose
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
      }
    />
  );
};

export default GenomeExport;
