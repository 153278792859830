import { Box } from "@mui/material";
import { useEffect } from "react";

const AddToAny = ({
  color = "#000000",
  textColor = "white",
  border = true,
}) => {
  useEffect(() => {
    // Dynamically load the AddToAny script
    const script = document.createElement("script");
    script.src = "https://static.addtoany.com/menu/page.js";
    script.async = true;

    document.body.appendChild(script);

    // Optional: Configure as needed
    window.a2a_config = window.a2a_config || {};
    window.a2a_config.onclick = 1;

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      className="a2a_kit a2a_kit_size_24 a2a_default_style"
      title="Share anywhere"
      data-a2a-icon-color={color}
      sx={{
        minWidth: "85px", // fix for: icon sometimes doesn't appear, creating 0 padding on left, but adding extra left padding looks bad for when it does appear
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: border ? "1px solid #ffffff1c" : null,
        borderRadius: "15px",
        "&:hover": {
          opacity: "0.8",
        },
      }}
    >
      <a
        className="a2a_dd"
        href="https://www.addtoany.com/share"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: ".95rem",
          fontWeight: "600",
          textDecoration: "none",
          color: textColor, // only controls text color, not plus icon color
        }}
      >
        Share
      </a>
    </Box>
  );
};

export default AddToAny;
